import React from "react";

function Dice(props) {
  const { value, dark, used } = props;

  const dots = [
    [],
    [4],
    [0, 8],
    [2, 4, 6],
    [0, 2, 6, 8],
    [0, 2, 4, 6, 8],
    [0, 2, 3, 5, 6, 8],
  ];
  return (
    <div className={`dice-cube ${dark ? "dark" : ""} ${used ? "used" : ""}`}>
      {[...Array(9)].map((_, i) => (
        <div
          key={i}
          className={`dot ${!dots[value].includes(i) ? "hidden" : ""}`}
        />
      ))}
    </div>
  );
}

export default Dice;
