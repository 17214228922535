import React from "react";

function Piece(props) {
  const { turn, pos, dark, clickPiece } = props;

  const click = () => {
    if (!turn || (turn === 1 && dark) || (turn === -1 && !dark))
      clickPiece(pos);
  };
  return <li onClick={click} className={`piece ${dark ? "dark" : "light"}`} />;
}

export default Piece;
