import Tabla from "./components/Tabla";
import SignIn from "./components/SignIn";
import { auth } from "./firebase-config.js";
import { useAuthState } from "react-firebase-hooks/auth";

import "./App.scss";

// https://www.makeuseof.com/build-chat-app-with-react-and-firebase/

function App() {
  const [user] = useAuthState(auth);
  return <>{user ? <Tabla /> : <SignIn />}</>;
}

export default App;
