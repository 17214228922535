import React, { useCallback, useEffect, useState, useRef } from "react";
import Piece from "./Piece";
import Dice from "./Dice";
import { id, onlyUnique } from "../utils";
import { db, auth } from "../firebase-config";
import {
  collection,
  query,
  onSnapshot,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";

function Tabla() {
  const saveButton = useRef();
  //   const mouse = useRef();

  const { uid } = auth.currentUser;

  const [docId, setDocId] = useState();
  const [reset, setReset] = useState(0);
  const [idle, setIdle] = useState(true);
  const [turn, setTurn] = useState(0);
  const [users, setUsers] = useState(["", ""]);
  const [position, setPosition] = useState();
  const [pips, setPips] = useState([0, 0]);
  const [outs, setOuts] = useState(); // 0 for turn -1, 1 for turn 1
  const [dice, setDice] = useState([]);
  const [selectedPiece, setSelectedPiece] = useState(0);
  const [validMoves, setValidMoves] = useState([]);
  const [canChangeTurn, setCanChangeTurn] = useState(false);
  const [rotated, setRotated] = useState(false);

  const turnIndex = () => {
    return turn === 1 ? 1 : 0;
  };

  const getUserId = useCallback(() => {
    return id(users[turn === 1 ? 1 : 0]);
  }, [users, turn]);

  const myTurn = useCallback(() => {
    return true; // TODO: remove
    // return id(uid) === getUserId(turn);
  }, [getUserId, turn, uid]);

  const loadCurrentState = (data) => {
    setDocId(data.id);

    const json = data.moves;

    const state = JSON.parse(json);
    // console.log("🟢", JSON.stringify(state, null, 2));

    setPosition(state.position);
    setOuts(state.outs);
    setTurn(state.turn);
    setDice(state.dice);
    setUsers(state.users);
  };

  const saveCurrentState = async (e) => {
    const data = {
      position: [...position],
      outs: [...outs],
      turn: turn === 1 ? 1 : -1,
      dice: [...dice],
      users: [...users],
    };

    const payload = JSON.stringify(data);

    // console.log(docId);

    const movesRef = doc(db, "state", docId);

    const { uid } = auth.currentUser;

    await updateDoc(movesRef, {
      moves: payload,
      createdAt: serverTimestamp(),
      uid: uid,
    }).then(() => {
      console.log("Saved!");
    });
  };

  const resetTable = () => {
    setPosition([
      0,
      // Bottom right
      -2, 0, 0, 0, 0, 5,
      // Bottom left
      0, 3, 0, 0, 0, -5,
      // Top left
      5, 0, 0, 0, -3, 0,
      // Top-right
      -5, 0, 0, 0, 0, 2,
    ]);

    // setPosition([
    //   0,
    //   // Bottom right
    //   0, 0, 2, 3, 4, 5,
    //   // Bottom left
    //   0, 1, 0, 0, 0, 0,
    //   // Top left
    //   0, 0, 0, 0, -1, 0,
    //   // Top-right
    //   -5, -4, -3, -2, 0, 0,
    // ]);
    setOuts([0, 0]);
    setTurn(Math.random() > 0.5 ? 1 : -1);
    rollDice(true);
    clearHighlights();
    delaySave();
  };

  const clickPiece = (pos) => {
    console.log("");
    console.log(`🟣 ${getUserId(turn)}`);
    console.log("clickPiece", pos);
    let allowed = true;

    // Player can only select own pieces:
    if (pos < 25 && turn * position[pos] < 0) allowed = false;

    // Cannot select pieces on the table while player has pieces out:
    if (pos < 25 && outs[(turn + 1) / 2] > 0) allowed = false;

    // Can always play out pieces:
    if (pos === 25) allowed = true;

    // Now allowed if it's not current player's turn:
    if (!myTurn()) allowed = false;

    console.log("allowed", allowed);

    if (allowed) {
      let valid = dice
        .map((d) =>
          d !== null && d.used === false ? pos - turn * d.value : null
        )
        .filter((v) => typeof v === "number") // No null dice
        .filter(onlyUnique) // Unique moves (avoid doubles)
        .filter((p) => !position[p] || position[p] * -turn < 2); // No oppnent stack

      //   console.log("🟣 valid", valid);

      let newValid = [];

      valid.forEach((v) => {
        // if (v > 24) {
        //   v = allInHouse[0] ? 25 : null;
        // }

        // Take out with bigger than necessary dice
        if (v < 0) {
          if (
            position[pos + turn] <= 0 &&
            position[pos + turn * 2] <= 0 &&
            position[pos + turn * 3] <= 0 &&
            position[pos + turn * 4] <= 0 &&
            position[pos + turn * 5] <= 0 &&
            position[pos + turn * 6] <= 0
          ) {
            v = allInHouse[1] ? 0 : null;
          } else v = null;
        }

        if (v > 25) {
          if (
            position[pos + turn] >= 0 &&
            position[pos + turn * 2] >= 0 &&
            position[pos + turn * 3] >= 0 &&
            position[pos + turn * 4] >= 0 &&
            position[pos + turn * 5] >= 0 &&
            position[pos + turn * 6] >= 0
          ) {
            v = allInHouse[0] ? 25 : null;
            // console.log("🟢");
          } else v = null;
        }

        newValid.push(v);
      });

      //   console.log("🟣🟣 newValid", newValid);
      valid = newValid.filter((v) => typeof v === "number").filter(onlyUnique);

      //   console.log("🟣🟣 valid", valid);

      if (!allInHouse[1]) {
        valid = valid.filter((v) => v > 0);
      }

      if (!allInHouse[0]) {
        valid = valid.filter((v) => v < 25);
      }

      // Adding out postion valid moves

      const diceValues = [];

      if (dice[0] && !dice[0].used) diceValues.push(dice[0].value);
      if (dice[1] && !dice[1].used) diceValues.push(dice[1].value);
      if (dice[2] && !dice[2].used) diceValues.push(dice[2].value);
      if (dice[3] && !dice[3].used) diceValues.push(dice[3].value);

      if (outs[1] > 0 && turn === 1) {
        for (let i = 19; i <= 24; i++) {
          if (
            (Math.abs(position[i]) < 2 || position[i] * turn >= 0) &&
            diceValues.includes(25 - i)
          ) {
            valid.push(i);
            console.log("1", i);
          }
        }
      }

      if (outs[0] > 0 && turn === -1) {
        for (let i = 1; i <= 6; i++) {
          if (
            (Math.abs(position[i]) < 2 || position[i] * turn >= 0) &&
            diceValues.includes(i)
          ) {
            valid.push(i);
            console.log("0", i);
          }
        }
      }

      valid = valid.filter(onlyUnique);

      //   console.log("🟣🟣🟣 valid", valid);

      if (valid.length) {
        // console.log("⬤", pos);
        // console.log("Valid moves:", valid);
        if (valid.length === 1) {
          if (
            (valid[0] > 24 && allInHouse[0]) ||
            (valid[0] < 1 && allInHouse[1])
          ) {
            removePiece(pos);
            consumeDice(pos);
          } else {
            movePiece(pos, valid[0]);
          }
        } else {
          setSelectedPiece(pos);
          setValidMoves(valid);
        }
      }
    }
  };

  const consumeDice = (selectedPiece, pos) => {
    const sel = selectedPiece;
    console.log("consumeDice", selectedPiece, pos);

    const newDice = [...dice];
    let usedDice = -1;

    if (pos !== undefined) {
      // When postion is known
      const jump =
        selectedPiece === 25 && turn === -1
          ? pos
          : Math.abs(pos - selectedPiece);
      usedDice = dice.findIndex((d) => d.value === jump && d.used === false);
    } else {
      console.table(dice);
      console.log(sel);
      if (
        !dice[0].used &&
        (dice[0].value === sel || dice[0].value === 25 - sel)
      ) {
        usedDice = 0;
      } else if (
        !dice[1].used &&
        (dice[1].value === sel || dice[1].value === 25 - sel)
      ) {
        usedDice = 1;
      } else if (
        dice[2] &&
        !dice[2].used &&
        (dice[2].value === sel || dice[2].value === 25 - sel)
      ) {
        usedDice = 2;
      } else if (
        dice[3] &&
        !dice[3].used &&
        (dice[3].value === sel || dice[3].value === 25 - sel)
      ) {
        usedDice = 3;
      } else {
        // Just pick the biggest dice for taking out
        let biggestDice = 0;

        console.table(dice);

        dice.forEach((d) => {
          if (!d.used) biggestDice = Math.max(d.value, biggestDice);
        });

        usedDice = dice.findIndex((d) => d.value === biggestDice && !d.used);

        console.log("biggestDice", biggestDice, dice[usedDice]);
      }
    }

    // Take out a piece with a bigger than necessary dice:
    if (usedDice === -1) {
      if (sel > 18) {
        usedDice = dice.findIndex((d) => !d.used && d.value >= 25 - sel);
      } else {
        usedDice = dice.findIndex((d) => !d.used && d.value >= sel);
      }
    }

    newDice[usedDice].used = true;
    setDice(newDice);
  };

  const movePiece = (selectedPiece, pos) => {
    console.log("movePiece");
    const newPosition = [...position];

    if (selectedPiece === 25) {
      const newOuts = outs;
      if (turn === 1) {
        newOuts[1]--;
      } else {
        newOuts[0]--;
      }
      setOuts(newOuts);
    } else {
      newPosition[selectedPiece] -= turn;
    }

    if (position[pos] * turn < 0) {
      newPosition[pos] = turn;
      outs[(-turn + 1) / 2]++;
    } else {
      newPosition[pos] += turn;
    }

    setPosition(newPosition);

    // Consume the dice:
    consumeDice(selectedPiece, pos);

    clearHighlights();
  };

  const removePiece = (pos) => {
    console.log("removePiece");
    const newPosition = [...position];
    newPosition[pos] -= turn;
    setPosition(newPosition);
    clearHighlights();
  };

  const clickDrop = (pos) => {
    if (pos === 25) {
      removePiece(selectedPiece);
      consumeDice(selectedPiece, pos);

      clearHighlights();
    } else {
      if (selectedPiece === 25 || turn * position[selectedPiece] > 0) {
        // Apply the move:
        movePiece(selectedPiece, pos);

        clearHighlights();
      }
    }
  };

  const changeTurn = (save = false) => {
    setTurn(-turn);
    rollDice();
    if (save) {
      delaySave();
    }
  };

  const rollDice = (avoidDouble = false) => {
    const a = Math.floor(Math.random() * 6) + 1;
    const b = Math.floor(Math.random() * 6) + 1;

    if (avoidDouble && a === b) {
      rollDice(true);
    } else {
      if (a === b) {
        setDice([
          { value: a, used: false },
          { value: a, used: false },
          { value: a, used: false },
          { value: a, used: false },
        ]);
      } else {
        setDice([
          { value: a, used: false },
          { value: b, used: false },
        ]);
      }
    }
  };

  const delaySave = () => {
    setIdle(false);
    setTimeout(() => {
      saveButton.current.click();
      setIdle(true);
    }, 100);
  };

  const acceptMove = () => {
    changeTurn();
    delaySave();
  };

  const getPieces = (pos) => {
    const p = position[pos];

    if (p !== 0) {
      const count = Math.abs(position[pos]);
      return (
        <ul
          className={`stack-${count} ${
            selectedPiece === pos ? "selected-stack" : ""
          }`}
        >
          {[...Array(count)].map((e, i) => (
            <Piece key={i} pos={pos} dark={p > 0} clickPiece={clickPiece} />
          ))}
          {validMoves.includes(pos) ? (
            <div className="drop" onClick={() => clickDrop(pos)} />
          ) : null}
        </ul>
      );
    } else {
      return (
        <ul>
          {validMoves.includes(pos) ? (
            <div className="drop" onClick={() => clickDrop(pos)} />
          ) : null}
        </ul>
      );
    }
  };

  useEffect(() => {
    const q = query(collection(db, "state"));
    const data = onSnapshot(q, (QuerySnapshot) => {
      let moves = [];
      QuerySnapshot.forEach((doc) => {
        moves.push({ ...doc.data(), id: doc.id });
      });
      loadCurrentState(moves[0]);
    });
    return () => data;
  }, [reset]);

  useEffect(() => {
    // If it's the last dice change turn and roll dice
    if (dice.findIndex((p) => p.used === false) === -1 && myTurn()) {
      setCanChangeTurn(true);
    } else {
      setCanChangeTurn(false);
    }
  }, [dice, myTurn, idle]);

  const [allInHouse, setAllInHouse] = useState([false, false]);

  useEffect(() => {
    if ((position, outs)) {
      const newAllInHouse = [false, false];

      if (outs[0] === 0) {
        const relevantPositions = [...position];
        relevantPositions.splice(19, 6);
        relevantPositions.splice(0, 1);
        // console.log("-1", relevantPositions);
        // console.log(relevantPositions.find((p) => p < 0));
        if (relevantPositions.find((p) => p < 0) === undefined) {
          newAllInHouse[0] = true;
        }
      }

      if (outs[1] === 0) {
        const relevantPositions = [...position];
        relevantPositions.splice(1, 6);
        relevantPositions.splice(0, 1);
        // console.log("1", relevantPositions);
        // console.log(
        //   "1",
        //   relevantPositions.find((p) => p > 0)
        // );
        if (relevantPositions.find((p) => p > 0) === undefined) {
          newAllInHouse[1] = true;
        }
      }

      setAllInHouse(newAllInHouse);
    }
  }, [position, outs]);

  useEffect(() => {
    if ((position, outs)) {
      let pipsLight = 0;
      let pipsDark = 0;

      position.forEach((p, index) => {
        if (p > 0) {
          pipsDark += p * index;
        }
        if (p < 0) {
          pipsLight += -p * (25 - index);
        }
      });

      setPips([pipsLight + outs[0] * 25, pipsDark + outs[1] * 25]);
    }
  }, [position, outs]);

  //   useEffect(() => {
  //     const q = query(collection(db, "mouse"));
  //     const data = onSnapshot(q, (QuerySnapshot) => {
  //       let moves = [];
  //       QuerySnapshot.forEach((doc) => {
  //         moves.push({ ...doc.data(), id: doc.id });
  //       });
  //       //   console.log(moves[0].pos);
  //     });
  //     return () => data;
  //   }, []);

  //   document.addEventListener("mousemove", (e) => {
  //     try {
  //       mouse.current.style = "top:" + e.pageY + "px; left:" + e.pageX + "px;";

  //       const mouseRef = doc(db, "mouse", "NReHY02t1woSF203FWmj");

  //       updateDoc(mouseRef, {
  //         pos: [e.pageY, e.pageX],
  //       });
  //     } catch (err) {}
  //   });

  const clearHighlights = () => {
    setSelectedPiece(0);
    setValidMoves([]);
  };

  const triggerSave = () => {
    setReset(reset + 1);
  };

  const changeSeats = () => {
    setRotated(!rotated);
  };

  return (
    <main>
      <div className="controls">
        {/* <button onClick={() => auth.signOut()}>Sign Out</button> */}

        <button
          onClick={changeSeats}
          // disabled={!idle}
          className={rotated ? "light" : "dark"}
        >
          Change seat 🔃
        </button>

        <button
          onClick={resetTable}
          // disabled={!idle}
          className={rotated ? "light" : "dark"}
        >
          Reset table ⟲
        </button>

        {/* <button
          onClick={() => auth.signOut()}
          className={uid === users[1] ? "dark" : "light"}
        >
          <p>
            <strong> {id(uid)}</strong>
            <img alt="" className="small" src={photoURL} />
          </p>
          Sign Out
        </button> */}
        {/* {turn !== 0 ? <h2>🎲{getUserId(turn)}</h2> : <h1> Loading...</h1>} */}

        <button
          className={`big ${rotated ? "light" : "dark"}`}
          onClick={() => changeTurn(true)}
          // disabled={!idle}
        >
          Pass turn 🎲
        </button>
        <button
          onClick={() => {
            clearHighlights();
            triggerSave();
          }}
          className={`big ${rotated ? "light" : "dark"}`}
          // disabled={!idle}
        >
          Undo ⎌
        </button>
        <button
          disabled={!canChangeTurn || !idle}
          onClick={acceptMove}
          className="big accept"
        >
          {idle ? "Accept ✓" : "Saving ⏳"}
        </button>
        <button
          className="hidden"
          ref={saveButton}
          tabIndex="-1"
          onClick={saveCurrentState}
        >
          SAVE
        </button>
      </div>

      {position && position.length === 25 ? (
        <div className={`tabla ${rotated ? "rotated" : ""}`}>
          <div className="left half">
            <div className="quarter top">
              {[...Array(6)].map((_, i) => (
                <div key={i} className="pos">
                  <span>{i + 13}</span>
                  {getPieces(i + 13)}
                </div>
              ))}
            </div>
            <div className="quarter bottom">
              {[...Array(6)].map((_, i) => (
                <div key={i} className="pos">
                  <span>{i + 7}</span>
                  {getPieces(i + 7)}
                </div>
              ))}
            </div>
          </div>

          <div className="right half">
            <div className="quarter top">
              {[...Array(6)].map((_, i) => (
                <div key={i} className="pos">
                  <span>{i + 19}</span>
                  {getPieces(i + 19)}
                </div>
              ))}
              {allInHouse[turnIndex()] && validMoves.includes(25) ? (
                <div className="out" onClick={() => clickDrop(25)} />
              ) : null}
            </div>
            <div className="quarter bottom">
              {[...Array(6)].map((_, i) => (
                <div key={i} className="pos">
                  <span>{i + 1}</span>
                  {getPieces(i + 1)}
                </div>
              ))}
              {allInHouse[turnIndex()] && validMoves.includes(0) ? (
                <div className="out bottom" onClick={() => clickDrop(0)} />
              ) : null}
            </div>
          </div>

          <div className={`dice ${turn === 1 ? "dark" : ""}`}>
            {dice.map((d, i) => (
              <Dice key={i} value={d.value} dark={turn === 1} used={d.used} />
            ))}
          </div>

          <div
            className={`outs light stack-${outs[0]} ${
              selectedPiece === 25 && turn === -1 ? "selected-stack" : ""
            }`}
          >
            {[...Array(outs[0])].map((_, i) => (
              <Piece key={i} pos={25} turn={turn} clickPiece={clickPiece} />
            ))}
          </div>
          <div
            className={`outs dark stack-${outs[1]} ${
              selectedPiece === 25 && turn === 1 ? "selected-stack" : ""
            }`}
          >
            {[...Array(outs[1])].map((_, i) => (
              <Piece
                key={i}
                pos={25}
                turn={turn}
                dark
                clickPiece={clickPiece}
              />
            ))}
          </div>
          <div className="pips dark">{pips[1]}</div>
          <div className="pips light">{pips[0]}</div>
        </div>
      ) : null}
    </main>
  );
}
export default Tabla;
