import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA5STYTvZEIykCnvRwLCgocXEw2BsogWug",
  authDomain: "live-feed-app.firebaseapp.com",
  projectId: "live-feed-app",
  storageBucket: "live-feed-app.appspot.com",
  messagingSenderId: "729006168572",
  appId: "1:729006168572:web:67d7294f072e9bbb0d3a79",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { db, auth, provider };
