const id = (uid) => {
  switch (uid) {
    case "uxLH36SWivhxJjc2aLu3cGM6ZUv1":
      return "Radu";

    case "mb7MnCtYRCafAcpzvzyWqGIUntx1":
      return "Colibri";

    case "wqu6rzitPRQrbysEsXPhUIElp5G2":
      return "Serban";

    case "0rY73AOSWTTTKk7Ui4fl8XSDMHt1":
      return "Adi";

    default:
      return uid;
  }
};

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

const unique = (arr) => {
  return arr.filter(onlyUnique);
};

export { id, onlyUnique, unique };
